import CalendarMixin from "@/mixins/Calendar";
import AppTabs from "@/components/AppTabs.vue";
import AppSelect from "@/components/AppSelect.vue";
import MainTitle from "@/components/MainTitle.vue";
import MainDescription from "@/components/MainDescription.vue";
import { getEntitiesList, getEntityMetricsList } from "@/utils/enums";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import CategoriesTable from "@/components/Rating/CategoriesTable.vue";
import ProductsTable from "@/components/Rating/ProductsTable.vue";
import BrandsTable from "@/components/Rating/BrandsTable.vue";
import SellersTable from "@/components/Rating/SellersTable.vue";
const tableComponents = {
  category: CategoriesTable,
  product: ProductsTable,
  brand: BrandsTable,
  seller: SellersTable
};
import RatingForm from "@/components/Rating/RatingForm.vue";
import NewForm from "@/components/Rating/NewForm.vue";
import UpsDownsForm from "@/components/Rating/UpsDownsForm.vue";
import ConstructorForm from "@/components/Rating/ConstructorForm.vue";
const formComponents = {
  rating: RatingForm,
  new: NewForm,
  upsdowns: UpsDownsForm,
  constructor: ConstructorForm
};
export default {
  mixins: [CalendarMixin],
  meta() {
    return {
      title: this.$route.params.mp === "wb" ? "Рейтинги и ТОПы продаж на маркетплейсе Wildberries: товары, новинки, категории и бренды" : "Рейтинги и ТОПы продаж на маркетплейсе OZON: товары, новинки, категории и бренды",
      meta: [{
        name: "description",
        content: this.$route.params.mp === "wb" ? "SalesFinder - сервис детальной аналитики Вайлдберриз. Рейтинги и ТОПы продаж - за один клик!" : "SalesFinder - сервис детальной аналитики OZON. Рейтинги и ТОПы продаж - за один клик!"
      }]
    };
  },
  data() {
    var _initialState;
    let initialState = this.$route.query._state;
    if (initialState) {
      try {
        initialState = JSON.parse(initialState);
      } catch (e) {
        initialState = null;
      }
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          _state: undefined
        }
      });
    }
    const tabs = [{
      id: "categories",
      title: "Категории",
      entity: "category",
      form: "rating"
    }, {
      id: "products",
      title: "Товары",
      entity: "product",
      form: "rating"
    }, {
      id: "new",
      title: "Новинки",
      entity: "product",
      form: "new"
    }, {
      id: "brands",
      title: "Бренды",
      entity: "brand",
      form: "rating"
    }, {
      id: "sellers",
      title: "Продавцы",
      entity: "seller",
      form: "rating"
    }

    /* //&: 2022-02-14 : api_CH пока не реализован
            {
                id: 'ups',
                title: 'Взлеты',
                form: 'upsdowns',
                type: 'ups'
            },
            {
                id: 'downs',
                title: 'Падения',
                form: 'upsdowns',
                type: 'downs'
            },
            {
                id: 'constructor',
                title: 'Конструктор',
                form: 'constructor'
            }
            */];
    return {
      tabs,
      tabs_active: null,
      show_table: false,
      form_data: null,
      tableKey: 0,
      size: 5000,
      initialState: (_initialState = initialState) === null || _initialState === void 0 ? void 0 : _initialState.form
    };
  },
  methods: {
    getTableId() {
      return this._uid;
    },
    onSubmit({
      entity,
      action,
      config
    }) {
      this.$access.hasOrThrow("ratings");
      this.form_data = {
        entity,
        action,
        config
      };
    },
    analyze() {
      this.show_table = true;
    }
  },
  computed: {
    track() {
      var _this$form_data, _this$form_data2, _this$form_data3;
      const entity = this.form_data.entity;
      let entityText = getEntitiesList("manyGenitive", "lower")[entity];
      if (this.tabs_active.form === "new") {
        entityText = "новинок";
      }
      let title = `Рейтинг ${entityText}`;
      const metric = (_this$form_data = this.form_data) === null || _this$form_data === void 0 || (_this$form_data = _this$form_data.config) === null || _this$form_data === void 0 ? void 0 : _this$form_data.metric;
      if (metric) {
        title += ` по ${getEntityMetricsList(entity, "dative")[metric]}`;
      }
      if (this.tabs_active.form === "constructor") {
        title = `Список ${entityText} по выбранным условиям`;
      }
      return {
        url: this.$route.fullPath,
        type: `${this.$route.params.mp.toUpperCase()} / Рейтинг ${entityText}`,
        name: title,
        form: this.form_data,
        date: (_this$form_data2 = this.form_data) === null || _this$form_data2 === void 0 || (_this$form_data2 = _this$form_data2.config) === null || _this$form_data2 === void 0 ? void 0 : _this$form_data2.date,
        date2: (_this$form_data3 = this.form_data) === null || _this$form_data3 === void 0 || (_this$form_data3 = _this$form_data3.config) === null || _this$form_data3 === void 0 ? void 0 : _this$form_data3.date2
      };
    },
    fileName() {
      return `${this.track.name} ${this.track.date} ${this.track.date2}`;
    },
    formComponentBindings() {
      if (!this.tabs_active) {
        return;
      }
      return {
        is: formComponents[this.tabs_active.form],
        entity: this.tabs_active.entity,
        type: this.tabs_active.type,
        calendarBoundaries: this.calendarBoundaries
      };
    },
    tableComponentBindings() {
      if (!this.form_data) {
        return null;
      }
      return {
        is: tableComponents[this.form_data.entity],
        action: this.form_data.action,
        reportSettings: {
          ...this.form_data.config,
          size: this.size,
          mp: this.$route.params.mp
        }
      };
    },
    tableRouteParams() {
      return {
        mp: this.$route.params.mp
      };
    },
    tableTitle() {
      var _this$form_data4;
      if (!this.form_data) {
        return;
      }
      const entity = this.form_data.entity;
      let entityText = getEntitiesList("manyGenitive", "lower")[entity];
      if (this.tabs_active.form === "new") {
        entityText = "новинок";
      }
      if (this.tabs_active.form === "constructor") {
        return `Список ${entityText} по выбранным условиям`;
      }
      let result = `ТОП-${this.size} ${entityText}`;
      const metric = (_this$form_data4 = this.form_data) === null || _this$form_data4 === void 0 || (_this$form_data4 = _this$form_data4.config) === null || _this$form_data4 === void 0 ? void 0 : _this$form_data4.metric;
      if (metric) {
        result += ` по ${getEntityMetricsList(entity, "dative")[metric]} `;
      }
      return result;
    },
    ready() {
      return !!this.calendarBoundaries;
    }
  },
  watch: {
    tabs_active() {
      this.form_data = null;
    },
    tableComponentBindings() {
      this.tableKey++;
    }
  },
  components: {
    AppTabs,
    AppSelect,
    MainTitle,
    MainDescription,
    DataTableActions
  }
};