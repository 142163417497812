var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "rating-form"
  }, [_c('app-select', {
    attrs: {
      "items": _vm.metric_items,
      "width": "288"
    },
    model: {
      value: _vm.metric_model,
      callback: function ($$v) {
        _vm.metric_model = $$v;
      },
      expression: "metric_model"
    }
  }), _c('checkbox', {
    attrs: {
      "title": "Включить данные FBS",
      "padding": ""
    },
    model: {
      value: _vm.fbs,
      callback: function ($$v) {
        _vm.fbs = $$v;
      },
      expression: "fbs"
    }
  }), _vm.calendar ? _c('range-calendar', {
    attrs: {
      "boundaries": _vm.calendarBoundaries
    },
    model: {
      value: _vm.calendar,
      callback: function ($$v) {
        _vm.calendar = $$v;
      },
      expression: "calendar"
    }
  }) : _vm._e(), _c('button', {
    staticClass: "btn-blue btn-get-rating",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Построить рейтинг ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };