var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "rating-form"
  }, [_c('app-select', {
    attrs: {
      "items": _vm.entity_items,
      "width": "288"
    },
    model: {
      value: _vm.entity_model,
      callback: function ($$v) {
        _vm.entity_model = $$v;
      },
      expression: "entity_model"
    }
  }), _c('checkbox', {
    attrs: {
      "title": "Включить данные FBS",
      "padding": ""
    },
    model: {
      value: _vm.fbs,
      callback: function ($$v) {
        _vm.fbs = $$v;
      },
      expression: "fbs"
    }
  }), _vm.calendar ? _c('range-calendar', {
    attrs: {
      "boundaries": _vm.calendarBoundaries
    },
    model: {
      value: _vm.calendar,
      callback: function ($$v) {
        _vm.calendar = $$v;
      },
      expression: "calendar"
    }
  }) : _vm._e(), _c('button', {
    staticClass: "btn-blue btn-get-rating",
    attrs: {
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Построить список ")])], 1), _c('div', {
    staticClass: "rating-constructor"
  }, [_c('fade-transition', {
    attrs: {
      "group": ""
    }
  }, _vm._l(_vm.constructor, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "rating-constructor__item"
    }, [_c('app-select', {
      attrs: {
        "items": _vm.constructorAvailableMetrics[item.id],
        "width": "288"
      },
      model: {
        value: item.select_model,
        callback: function ($$v) {
          _vm.$set(item, "select_model", $$v);
        },
        expression: "item.select_model"
      }
    }), _c('label', {
      staticClass: "rating-constructor__item-label"
    }, [_vm._v(" От "), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.input_from,
        expression: "item.input_from"
      }],
      staticClass: "rating-constructor__item-input",
      attrs: {
        "type": "text",
        "placeholder": _vm.getMin(item)
      },
      domProps: {
        "value": item.input_from
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) return;
          _vm.$set(item, "input_from", $event.target.value);
        }
      }
    })]), _c('label', {
      staticClass: "rating-constructor__item-label"
    }, [_vm._v(" До "), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.input_to,
        expression: "item.input_to"
      }],
      staticClass: "rating-constructor__item-input",
      attrs: {
        "type": "text",
        "placeholder": _vm.getMax(item)
      },
      domProps: {
        "value": item.input_to
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) return;
          _vm.$set(item, "input_to", $event.target.value);
        }
      }
    })]), item.id != 1 ? _c('img', {
      staticClass: "rating-constructor__button-x",
      attrs: {
        "src": require(`@/assets/images/icons/circle_close.svg`)
      },
      on: {
        "click": function ($event) {
          return _vm.deleteItemFromConstructor(item.id);
        }
      }
    }) : _vm._e()], 1);
  }), 0)], 1), _vm.freeMetrics.length ? _c('div', [_c('div', {
    staticClass: "rating-constructor__button",
    on: {
      "click": _vm.addItemToConstructor
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/add_more.png`)
    }
  }), _vm._v(" Добавить условие ")])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };