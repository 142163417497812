var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "rating-form"
  }, [_c('app-select', {
    attrs: {
      "items": _vm.entity_items,
      "width": "180"
    },
    model: {
      value: _vm.entity_model,
      callback: function ($$v) {
        _vm.entity_model = $$v;
      },
      expression: "entity_model"
    }
  }), _c('app-select', {
    attrs: {
      "items": _vm.metric_items,
      "width": "288"
    },
    model: {
      value: _vm.metric_model,
      callback: function ($$v) {
        _vm.metric_model = $$v;
      },
      expression: "metric_model"
    }
  }), _c('checkbox', {
    attrs: {
      "title": "Включить данные FBS",
      "padding": ""
    },
    model: {
      value: _vm.fbs,
      callback: function ($$v) {
        _vm.fbs = $$v;
      },
      expression: "fbs"
    }
  }), _vm.calendar ? _c('range-calendar', {
    attrs: {
      "boundaries": _vm.calendarBoundaries,
      "absolute-title": "Период"
    },
    model: {
      value: _vm.calendar,
      callback: function ($$v) {
        _vm.calendar = $$v;
      },
      expression: "calendar"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "combined-calendar"
  }, [_c('app-select', {
    attrs: {
      "absolute-title": "Сравнить с",
      "items": _vm.range_preset_items,
      "fullwidth": ""
    },
    model: {
      value: _vm.range_preset_model,
      callback: function ($$v) {
        _vm.range_preset_model = $$v;
      },
      expression: "range_preset_model"
    }
  }), _vm.calendar2 ? _c('range-calendar', {
    staticClass: "combined-calendars-2",
    attrs: {
      "boundaries": _vm.calendarBoundaries,
      "disabled": _vm.range_preset_model.id !== 'custom',
      "fullWidthButton": ""
    },
    model: {
      value: _vm.calendar2,
      callback: function ($$v) {
        _vm.calendar2 = $$v;
      },
      expression: "calendar2"
    }
  }) : _vm._e()], 1), _c('button', {
    staticClass: "btn-blue btn-get-rating",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Построить рейтинг ")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };