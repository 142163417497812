import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import { getEntityMetricsListArray, getEntitiesListArray } from "@/utils/enums";
import { dateRangeFromDate, today, dateRangeBoundaries, default as RangeCalendar } from "@/components/RangeCalendar.vue";
import AppSelect from "@/components/AppSelect.vue";
import { FadeTransition } from "vue2-transitions";
import Checkbox from "@/components/Checkbox";
export default {
  components: {
    AppSelect,
    RangeCalendar,
    FadeTransition,
    Checkbox
  },
  props: {
    calendarBoundaries: {
      type: Array,
      required: true
    },
    initialState: {
      type: Object,
      default: null
    }
  },
  data() {
    const entity_items = getEntitiesListArray();
    const entity_model = entity_items[0];
    const metric_items = getEntityMetricsListArray(entity_model.id);
    return {
      entity_items,
      entity_model,
      metric_items,
      calendar: null,
      constructor_metric_model: metric_items[0],
      constructor: [{
        id: 1,
        select_items: metric_items,
        select_model: metric_items[0],
        input_from: "",
        input_to: ""
      }],
      ranges: {},
      fbs: false
    };
  },
  async mounted() {
    if (this.initialState) {
      const {
        entity,
        config = {}
      } = this.initialState;
      const {
        fbs,
        date,
        date2
      } = config;
      if ([entity, fbs, date, date2].some(i => i === undefined)) {
        return;
      }
      this.entity_model = this.entity_items.find(i => i.id === entity) || this.entity_model;
      await this.$nextTick();
      this.fbs = !!fbs;
      this.calendar = [date, date2];
      let first = true;
      this.metric_items.forEach(item => {
        const metric = item.id;
        const min = config[`rf.${metric}.min`];
        const max = config[`rf.${metric}.max`];
        if (!min && !max) {
          return;
        }
        if (!first) {
          this.addItemToConstructor();
        }
        const last = this.constructor[this.constructor.length - 1];
        last.select_model = item;
        last.input_from = min || "";
        last.input_to = max || "";
        first = false;
      });
      if (this.valid) {
        this.onSubmit();
      }
    }
  },
  methods: {
    getVuexAction() {
      var _this$entity_model;
      const entity = (_this$entity_model = this.entity_model) === null || _this$entity_model === void 0 ? void 0 : _this$entity_model.id;
      const actions = {
        category: "rating/getConstructorCategories",
        product: "rating/getConstructorProducts",
        brand: "rating/getConstructorBrands",
        seller: "rating/getConstructorSellers"
      };
      return actions[entity];
    },
    onSubmit() {
      var _this$entity_model2, _this$metric_model;
      const entity = (_this$entity_model2 = this.entity_model) === null || _this$entity_model2 === void 0 ? void 0 : _this$entity_model2.id;
      this.$emit("submit", {
        entity,
        action: this.getVuexAction(),
        config: {
          metric: (_this$metric_model = this.metric_model) === null || _this$metric_model === void 0 ? void 0 : _this$metric_model.id,
          fbs: this.fbs ? 1 : 0,
          date: this.calendar[0],
          date2: this.calendar[1],
          ...this.filters
        }
      });
    },
    addItemToConstructor() {
      this.constructor.push({
        id: this.constructor[this.constructor.length - 1].id + 1,
        select_model: this.freeMetrics[0],
        input_from: "",
        input_to: ""
      });
    },
    deleteItemFromConstructor(id) {
      let res = this.constructor.filter(item => item.id != id);
      this.constructor = [...res];
    },
    async loadRanges() {
      var _this$metric_model2;
      const action = this.getVuexAction();
      const {
        ranges
      } = await this.$store.dispatch(action, {
        metric: (_this$metric_model2 = this.metric_model) === null || _this$metric_model2 === void 0 ? void 0 : _this$metric_model2.id,
        fbs: this.fbs ? 1 : 0,
        date: this.calendar[0],
        date2: this.calendar[1],
        select: ["ranges"]
      });
      this.ranges = ranges;
    },
    getMin(item) {
      var _this$ranges$item$sel, _item$select_model;
      return (_this$ranges$item$sel = this.ranges[(_item$select_model = item.select_model) === null || _item$select_model === void 0 ? void 0 : _item$select_model.id]) === null || _this$ranges$item$sel === void 0 ? void 0 : _this$ranges$item$sel[0];
    },
    getMax(item) {
      var _this$ranges$item$sel2, _item$select_model2;
      return (_this$ranges$item$sel2 = this.ranges[(_item$select_model2 = item.select_model) === null || _item$select_model2 === void 0 ? void 0 : _item$select_model2.id]) === null || _this$ranges$item$sel2 === void 0 ? void 0 : _this$ranges$item$sel2[1];
    }
  },
  computed: {
    metric_model() {
      return this.constructor[0].select_model;
    },
    usedMetricsIds() {
      return this.constructor.map(item => {
        var _item$select_model3;
        return (_item$select_model3 = item.select_model) === null || _item$select_model3 === void 0 ? void 0 : _item$select_model3.id;
      });
    },
    freeMetrics() {
      return this.metric_items.filter(metric => !this.usedMetricsIds.includes(metric === null || metric === void 0 ? void 0 : metric.id));
    },
    constructorAvailableMetrics() {
      const result = {};
      this.constructor.forEach(item => {
        result[item.id] = this.metric_items.filter(metric => {
          var _item$select_model4;
          return ((_item$select_model4 = item.select_model) === null || _item$select_model4 === void 0 ? void 0 : _item$select_model4.id) === (metric === null || metric === void 0 ? void 0 : metric.id) || !this.usedMetricsIds.includes(metric === null || metric === void 0 ? void 0 : metric.id);
        });
      });
      return result;
    },
    filters() {
      const filters = {};
      this.constructor.forEach(item => {
        var _item$select_model5;
        const metric = (_item$select_model5 = item.select_model) === null || _item$select_model5 === void 0 ? void 0 : _item$select_model5.id;
        if (item.input_from && !isNaN(item.input_from)) {
          filters[`rf.${metric}.min`] = Number(item.input_from);
        }
        if (item.input_to && !isNaN(item.input_to)) {
          filters[`rf.${metric}.max`] = Number(item.input_to);
        }
      });
      return filters;
    },
    valid() {
      return Object.keys(this.filters).length;
    },
    rangesKey() {
      var _this$entity_model3;
      return `${(_this$entity_model3 = this.entity_model) === null || _this$entity_model3 === void 0 ? void 0 : _this$entity_model3.id}`;
    }
  },
  watch: {
    calendarBoundaries: {
      immediate: true,
      handler(v) {
        var _this$calendarBoundar;
        if (!v) {
          return;
        }
        const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
        const weekRange = dateRangeFromDate(lastAvailableDate, 30);
        this.calendar = dateRangeBoundaries([weekRange[0], weekRange[1]], this.calendarBoundaries);
      }
    },
    entity_model() {
      this.constructor = [{
        id: 1,
        select_model: this.freeMetrics[0],
        input_from: "",
        input_to: ""
      }];
      this.metric_items = getEntityMetricsListArray(this.entity_model.id);
    },
    rangesKey: {
      immediate: true,
      handler() {
        this.loadRanges();
      }
    }
  }
};