import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.some.js";
import { getEntityMetricsListArray, getEntitiesListArray } from "@/utils/enums";
import { parseDate, formatDate, dateRangeDays, dateRangeFromDate, today, dateRangeBoundaries, default as RangeCalendar } from "@/components/RangeCalendar.vue";
import AppSelect from "@/components/AppSelect.vue";
import Checkbox from "@/components/Checkbox";
export default {
  components: {
    AppSelect,
    RangeCalendar,
    Checkbox
  },
  props: {
    calendarBoundaries: {
      type: Array,
      required: true
    },
    // ups / downs
    type: {
      type: String,
      required: true
    },
    initialState: {
      type: Object,
      default: null
    }
  },
  data() {
    const entity_items = getEntitiesListArray();
    const entity_model = entity_items[0];
    const metric_items = getEntityMetricsListArray(entity_model.id);
    const range_preset_items = [{
      id: "previous",
      title: "Предыдущий период"
    }, {
      id: "custom",
      title: "Выбрать вручную"
    }];
    return {
      entity_items,
      entity_model,
      metric_items,
      metric_model: metric_items[0],
      fbs: false,
      calendar: null,
      calendar2: null,
      range_preset_items: [{
        id: "previous",
        title: "Предыдущий период"
      }, {
        id: "custom",
        title: "Выбрать вручную"
      }],
      range_preset_model: range_preset_items[0]
    };
  },
  async mounted() {
    if (this.initialState) {
      const {
        entity,
        config = {}
      } = this.initialState;
      const {
        metric,
        fbs,
        date,
        date2,
        date3,
        date4
      } = config;
      if ([entity, metric, fbs, date, date2, date3, date4].some(i => i === undefined)) {
        return;
      }
      this.entity_model = this.entity_items.find(i => i.id === entity) || this.entity_model;
      await this.$nextTick();
      this.metric_model = this.metric_items.find(i => i.id === metric) || this.metric_model;
      this.fbs = !!fbs;
      this.calendar = [date, date2];
      this.range_preset_model = this.range_preset_items.find(i => i.id === "custom");
      this.calendar2 = [date3, date4];
      this.onSubmit();
    }
  },
  methods: {
    onSubmit() {
      var _this$entity_model, _this$metric_model;
      const entity = (_this$entity_model = this.entity_model) === null || _this$entity_model === void 0 ? void 0 : _this$entity_model.id;
      const actions = {
        category: "rating/getUpsDownsCategories",
        product: "rating/getUpsDownsProducts",
        brand: "rating/getUpsDownsBrands",
        seller: "rating/getUpsDownsSellers"
      };
      this.$emit("submit", {
        entity,
        action: actions[entity],
        config: {
          metric: (_this$metric_model = this.metric_model) === null || _this$metric_model === void 0 ? void 0 : _this$metric_model.id,
          type: this.type,
          fbs: this.fbs ? 1 : 0,
          date: this.calendar[0],
          date2: this.calendar[1],
          date3: this.calendar2[0],
          date4: this.calendar2[1]
        }
      });
    },
    setSecondCalendar() {
      var _this$range_preset_mo;
      if (((_this$range_preset_mo = this.range_preset_model) === null || _this$range_preset_mo === void 0 ? void 0 : _this$range_preset_mo.id) === "previous") {
        const days = dateRangeDays(this.calendar[0], this.calendar[1]);
        const end = parseDate(this.calendar[0]).subtract(1, "day");
        const start = end.clone().subtract(days - 1, "day");
        this.calendar2 = [formatDate(start), formatDate(end)];
      }
    }
  },
  computed: {},
  watch: {
    calendar() {
      this.setSecondCalendar();
    },
    range_preset_model(v) {
      if ((v === null || v === void 0 ? void 0 : v.id) === "previous") {
        this.setSecondCalendar();
      }
    },
    calendarBoundaries: {
      immediate: true,
      handler(v) {
        var _this$calendarBoundar;
        if (!v) {
          return;
        }
        const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
        const weekRange = dateRangeFromDate(lastAvailableDate, 30);
        this.calendar = dateRangeBoundaries([weekRange[0], weekRange[1]], this.calendarBoundaries);
        this.setSecondCalendar();
      }
    },
    entity_model(v) {
      this.metric_items = getEntityMetricsListArray(v.id);
      this.metric_model = this.metric_items[0];
    }
  }
};