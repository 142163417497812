var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.ready ? _c('div', {
    staticClass: "ratings"
  }, [_c('main-title', [_vm._v(" Рейтинги и ТОПы продаж на " + _vm._s(_vm.$route.params.mp === "wb" ? "Wildberries" : "Ozon") + " ")]), _c('main-description', {
    staticClass: "mt8"
  }, [_vm._v(" Здесь вы можете построить рейтинги по различным параметрам и периодам времени ")]), _c('div', {
    staticClass: "rating-tabs"
  }, [_c('app-tabs', {
    attrs: {
      "tabs": _vm.tabs,
      "route-param": "type"
    },
    model: {
      value: _vm.tabs_active,
      callback: function ($$v) {
        _vm.tabs_active = $$v;
      },
      expression: "tabs_active"
    }
  })], 1), _c('div', {
    staticClass: "rating-params mt32"
  }, [_vm.formComponentBindings ? _c(_vm.formComponentBindings.is, _vm._b({
    key: `form:${_vm.tabs_active.id}`,
    tag: "component",
    attrs: {
      "initial-state": _vm.initialState
    },
    on: {
      "submit": _vm.onSubmit
    }
  }, 'component', _vm.formComponentBindings, false)) : _vm._e()], 1), _vm.tableComponentBindings ? _c('div', {
    staticClass: "ratings-table-block mt48"
  }, [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('data-table-actions', {
          attrs: {
            "id": _vm.getTableId()
          }
        })];
      },
      proxy: true
    }], null, false, 2179267790)
  }, [_vm._v(" " + _vm._s(_vm.tableTitle) + " ")]), _c('div', {
    staticClass: "mt32"
  }, [_c(_vm.tableComponentBindings.is, _vm._b({
    key: `table:${_vm.tableKey}`,
    tag: "component",
    attrs: {
      "id": _vm.getTableId(),
      "route-params": _vm.tableRouteParams,
      "track": _vm.track,
      "file-name": _vm.fileName
    }
  }, 'component', _vm.tableComponentBindings, false))], 1)], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };